import StaffPanel from "./Parts/StaffPanel";

function Staff() {
    const staffs = [
        {
            name: '橋本 英郎',
            name_english: 'Hideo Hashimoto',
            profile:    '1979年生まれ、大阪府大阪市出身。サッカー元日本代表MF。大阪にある公立トップ校から、国公立大学に進学、卒業。学業とサッカーの両立をし、大学在籍中にJリーグの選手に。\n' +
                        '2022年現在もサッカー選手兼ヘッドコーチとして活躍中。これまでの指導で培ったコーチングを活た学習塾シュートを、専門家と共に立ち上げ。\n' +
                        'その他にも、サッカースクールやJrユースチーム、放課後デイサービスを経営。子どもたちが【心・技・体】すべてにおいて成長できるよう尽力してしている。',
            message_from: '代表より',
            message: '受験のプロ、西明石のプロの専門的な技と、私の人を育てる コーチングのノウハウで、西明石の子どもたちの成長に携わりたいと思います。ただただ真っ直ぐ、お越しいただく生徒様に志望校合格・点数 アップを通して、成長をお届けしたいです。',
            img: 'hashimoto.jpg'
        },
        {
            name: '髙見 絋司',
            name_english: 'Koji Takami',
            profile: '1985年生まれ、兵庫県三田市出身。大阪と兵庫で学習塾の教室長を務めた経験を持ち、また自らサッカースクールを経営する、経営者でもあった。代表 橋本との出会いにより、個別指導塾 シュートで自らの指導技術を、またサッカーJrユースチームのPUENTE FCや、プリメーラ放課 後等デイサービスで自らのコーチング力を提供することを決断。子どもたちが【何事も前向きに取り組む】ことによって成長できるよう日々指導に励んでいる。',
            message: '元気さとパワフルさが持ち味の髙見と申します! これまでに蓄積してきた人を育てるノウハウで、西明石の子どもたちの成長に携わりたいと現状に満足することなく、子どもたちと一緒に成長していきたいと考えています!',
            img: 'takami.jpg'
        },
        {
            name: '山根 隼人',
            name_english: 'Hayato Yamane',
            profile: '1998年生まれ、大阪府大阪市出身。 関西の難関国立大学を卒業した経歴を持つにも関わらず、サッカーと学業を両立してきた自分のように失敗を超えて成長できる子ども達を育てたいという強い想いを持って、現在、個別指導塾シュートのコーチ/プロ講師として活躍。生徒様が【文武両道】に成長していくためのサポートを行なっている。',
            message: '私は20代とまだ若い身ですが、その分生徒様に寄り添った指導 ができることが一番の強みです。すでに多くの生徒とともに歩み、 点数アップや志望校合格を体験してきました。\n' +
                     '“ただ勉強する” だけでは望むものは得られません...。それは成長とともに得られるのです。キミの成長の後押しをさせてください。先生と一緒に頑張ろう!',
            img: 'yamane.jpg'
        }
    ]
    return (
        <div id="Staff" className="w-11/12 m-auto font-sans">
            <div className="text-left">
                <div className='Title w-11/12 m-auto md:w-3/5'>
                    <span className='block text-5xl text-LightGray'>Staff</span>
                    <h2 className='text-2xl pl-3 text-Blue'>スタッフ紹介</h2>
                </div>

                <div>
                    {staffs && staffs.map((person) => (
                        <StaffPanel
                            name={person.name}
                            name_english={person.name_english}
                            profile={person.profile}
                            message_from={person.message_from}
                            message={person.message}
                            img={person.img}
                        />
                    ))}
                </div>
            </div>
        </div>
    ) 
}

export default Staff;